/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/********** Job Portal Website CSS **********/
:root {
  --primary: #00B074;
  --secondary: #2B9BFF;
  --light: #EFFDF5;
  --dark: #2B3940;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  transition: .5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}


/*** Navbar ***/
.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 5px;
  transition: .5s;
}

.navbar .dropdown-toggle[aria-expanded=true]::after {
  transform: rotate(-180deg);
}

.navbar-dark .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: #FFFFFF;
  font-size: 15px;
  text-transform: uppercase;
  outline: none;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--primary);
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-link,
  .navbar-light .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar-light .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}

.navbar-light .navbar-brand,
.navbar-light a.btn {
  height: 75px;
}

.navbar-light .navbar-nav .nav-link {
  color: var(--dark);
  font-weight: 500;
}

.navbar-light.sticky-top {
  top: -100px;
  transition: .5s;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      top: 100%;
      margin-top: 0;
      transform: rotateX(-75deg);
      transform-origin: 0% 0%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
      
  }

  .navbar .nav-item:hover .dropdown-menu {
      transform: rotateX(0deg);
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}


/*** Header ***/
.header-carousel .container,
.page-header .container {
  position: relative;
  padding: 45px 0 45px 35px;
  border-left: 15px solid var(--primary);
}

.header-carousel .container::before,
.header-carousel .container::after,
.page-header .container::before,
.page-header .container::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100px;
  height: 15px;
  background: var(--primary);
}

.header-carousel .container::after,
.page-header .container::after {
  top: 100%;
  margin-top: -15px;
}

@media (max-width: 768px) {
  .header-carousel .owl-carousel-item {
      position: relative;
      min-height: 500px;
  }
  
  .header-carousel .owl-carousel-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .header-carousel .owl-carousel-item p {
      font-size: 14px !important;
      font-weight: 400 !important;
  }

  .header-carousel .owl-carousel-item h1 {
      font-size: 30px;
      font-weight: 600;
  }
}

.header-carousel .owl-nav {
  position: absolute;
  top: 50%;
  right: 8%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
}

.header-carousel .owl-nav .owl-prev,
.header-carousel .owl-nav .owl-next {
  margin: 7px 0;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  background: transparent;
  border: 1px solid #FFFFFF;
  border-radius: 2px;
  font-size: 22px;
  transition: .5s;
}

.header-carousel .owl-nav .owl-prev:hover,
.header-carousel .owl-nav .owl-next:hover {
  background: var(--primary);
  border-color: var(--primary);
}

.page-header {
  /* background: linear-gradient(rgba(43, 57, 64, .5), rgba(43, 57, 64, .5)), url(../img/carousel_1.jpg) center center no-repeat; */
  background-size: cover;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}


/*** About ***/
.about-bg {
  background-image: -webkit-repeating-radial-gradient(center center, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 1px, transparent 1px, transparent 100%);
  background-image: -moz-repeating-radial-gradient(center center, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 1px, transparent 1px, transparent 100%);
  background-image: -ms-repeating-radial-gradient(center center, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 1px, transparent 1px, transparent 100%);
  background-image: -o-repeating-radial-gradient(center center, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 1px, transparent 1px, transparent 100%);
  background-image: repeating-radial-gradient(center center, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 1px, transparent 1px, transparent 100%);
  background-size: 5px 5px;
}


/*** Category ***/
.cat-item {
  display: block;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  border: 1px solid transparent;
  transition: .5s;
}

.cat-item:hover {
  border-color: rgba(0, 0, 0, .08);
  box-shadow: none;
}


/*** Job Listing ***/
.nav-pills .nav-item .active {
  border-bottom: 2px solid var(--primary);
}

.job-item {
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
  transition: .5s;
}

.job-item:hover {
  border-color: rgba(0, 0, 0, .08);
  box-shadow: none;
}


/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: #FFFFFF !important;
}

.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 2px;
  transition: .5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}


/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 2px;
  transition: .3s;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
  border-color: var(--light);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: .3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .form-control {
  border-color: rgba(255,255,255,0.5);
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}